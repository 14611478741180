const detectBarcodeScan = (
  callback,
  threshold = 50,
  minimumLength = 4,
  scanPrefix = "`",
  scanPostfix = "Enter"
) => {
  let inputBuffer = ""
  let lastTime = Date.now()

  const handleKeydown = (event) => {
    const currentTime = Date.now()
    const timeDifference = currentTime - lastTime
    lastTime = currentTime

    const keysToIgnore = [
      "Shift",
      "Control",
      "Alt",
      "Meta", // Windows key / Command key
      "Backspace",
      "Delete",
      "CapsLock",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Home",
      "End",
      "PageUp",
      "PageDown",
    ]

    // Immediately ignore all keysToIgnore
    if (keysToIgnore.includes(event.key)) {
      return
    }

    // Catch when the scanPostfix key is received, it is expected to be our final key.
    if (event.key === scanPostfix) {
      if (inputBuffer.length >= minimumLength) {
        event.preventDefault() // Catch the scanPostfix from being bubbled up.
        // Conditionally strip the prefix Character if one was initially sent.
        if (inputBuffer[0] === scanPrefix) {
          inputBuffer = inputBuffer.slice(1)
        }
        // Send the barcode back to caller.
        callback(inputBuffer)
      }
      // Finally, reset the inputBuffer.
      inputBuffer = ""
    } else {
      if (timeDifference < threshold) {
        // Handle 2nd and subsequent keys.
        inputBuffer += event.key
        // Prevent bubbling up if our inputBuffer starts with the scanPrefix.
        if (inputBuffer[0] === scanPrefix) {
          event.preventDefault()
        }
      } else {
        // Set first key.
        inputBuffer = event.key
        // Prevent the optional scanPrefix key from bubbling up.
        if (event.key === scanPrefix) {
          event.preventDefault()
        }
      }
    }
  }

  document.addEventListener("keydown", handleKeydown)
}

export default detectBarcodeScan
