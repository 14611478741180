import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import React from "react"
import { createPortal } from "react-dom"
import ReactDOM from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { BarcodeScannerProvider } from "src/providers/BarcodeScannerProvider"

import ErrorBoundaryProvider from "../components/ErrorBoundaryProvider"
import ToastContainer from "../components/Toast/ToastContainer"
import { ToastProvider } from "../components/Toast/ToastContext"
import { automountableComponents } from "./automountable-components"
import { legacyAutomountableComponents } from "./legacy-automountable-components"

if (window.bugsnagSettings) {
  Bugsnag.start({
    ...window.bugsnagSettings,
    plugins: [new BugsnagPluginReact()],
  })
}

const allAutomountableComponents = {
  ...automountableComponents,
  ...legacyAutomountableComponents,
}

export function automountReactElements() {
  console.log(`module React v${React.version} loaded`) // eslint-disable-line no-console
  const componentNames = []

  const renderReactPortalComponents = () => {
    // Get all tags generated by the `react_component_v2` helper.
    const mountingElements = document.querySelectorAll("[data-react-component]")

    return Array.from(mountingElements).map((mountingElement) => {
      const { name, props = {} } = JSON.parse(
        mountingElement.getAttribute("data-react-component")
      )

      componentNames.push(name)

      const Component = allAutomountableComponents[name]
      if (!Component) {
        return console.log(`Component ${name} not found`) // eslint-disable-line no-console
      } else {
        return createPortal(<Component {...props} />, mountingElement)
      }
    })
  }

  const renderReactLayout = () => {
    const main = document.getElementById("yield")
    const layoutRoot = document.createElement("div")
    main.appendChild(layoutRoot)

    const queryClient = new QueryClient()

    ReactDOM.createRoot(layoutRoot).render(
      <ErrorBoundaryProvider>
        <ToastProvider>
          <BarcodeScannerProvider>
            <QueryClientProvider client={queryClient}>
              {renderReactPortalComponents()}
              <div className="relative flex justify-center">
                <ToastContainer />
              </div>
              {!componentNames.includes("ChatContainer") && (
                <ReactQueryDevtools position="bottom-right" />
              )}
            </QueryClientProvider>
          </BarcodeScannerProvider>
        </ToastProvider>
      </ErrorBoundaryProvider>
    )
  }

  document.addEventListener("DOMContentLoaded", renderReactLayout, {
    once: true,
  })
}
