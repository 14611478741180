import pluralize from "pluralize"
import PropTypes from "prop-types"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { dollarsToCents } from "src/main/Billing/Items/helpers"

import Button from "src/components/Button"
import Form from "src/components/Form"
import HeadingModalTitle from "src/components/Heading/HeadingModalTitle"

import {
  createFuelDiscount,
  updateFuelDiscount,
} from "src/api/DockwaPlusDeals/Manage/Fuel"

import { useToast } from "src/hooks/use_toast"

import { centsToDollars, percentToDecimal } from "src/utils/UnitConversion"

const FuelDiscountForm = ({
  fuelDiscount,
  fuelProducts,
  dockwaPlusDealsPath,
}) => {
  const showToast = useToast()
  const determineApplicableType = () => {
    if (fuelDiscount) {
      return fuelDiscount.isProductType ? "ProductType" : "Product"
    } else {
      return "ProductType"
    }
  }

  const determineFuelProducts = () => {
    if (fuelDiscount) {
      if (fuelDiscount.isProductType) {
        return fuelProducts
      } else {
        return fuelDiscount.products
      }
    } else {
      return fuelProducts
    }
  }

  const determineDiscountUnit = () => {
    if (fuelDiscount) {
      return fuelDiscount.amount ? "amount" : "percent"
    } else if (
      new URLSearchParams(window.location.search).get("percent_amount")
    ) {
      return "percent"
    } else {
      return "amount"
    }
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      label:
        fuelDiscount?.label ||
        new URLSearchParams(window.location.search).get("label") ||
        "",
      discountUnit: determineDiscountUnit(),
      amount:
        centsToDollars(fuelDiscount?.amount) ||
        new URLSearchParams(window.location.search).get("amount") ||
        "",
      percentAmount:
        fuelDiscount?.percentAmount ||
        new URLSearchParams(window.location.search).get("percent_amount") ||
        "",
      applicableType: determineApplicableType(),
      fuelProducts: determineFuelProducts(),
      source: "dockwa_plus",
    },
  })

  const handleMutate = (data) => {
    data.percentAmount = percentToDecimal(data.percentAmount)
    data.amount = dollarsToCents(data.amount)

    if (fuelDiscount) {
      return updateFuelDiscount({ data, id: fuelDiscount.id })
    } else {
      return createFuelDiscount({ data })
    }
  }

  const { mutate, isLoading, isSuccess } = useMutation(
    (data) => handleMutate(data),
    {
      onSuccess: (data) => {
        showToast(data.message, { type: "success" })
        window.location.href = dockwaPlusDealsPath
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const onSubmit = (data) => {
    mutate(data)
  }

  const renderLabelField = () => (
    <div className="col-span-12 flex flex-col md:col-span-6 xl:col-span-4">
      <Form.Label htmlFor="label" required>
        Label
      </Form.Label>
      <Form.TextField
        hasErrors={!!errors.label}
        id="label"
        {...register("label", { required: "Label is required" })}
      />
      {errors.label && <Form.Error>{errors.label.message}</Form.Error>}
    </div>
  )

  const renderDiscountUnitField = () => (
    <div className="col-span-12 flex flex-col md:col-span-6 xl:col-span-4">
      <Form.Label htmlFor="discountUnit">Discount Unit</Form.Label>
      <Controller
        name="discountUnit"
        control={control}
        render={({ field: { onChange } }) => (
          <Form.Select.Custom
            id="discountUnit"
            onChange={(value) => {
              onChange(value)
              setValue("amount", "")
              setValue("percentAmount", "")
            }}
            value={watch("discountUnit")}
          >
            <Form.Select.RichOption value="amount">
              Amount off per gallon
            </Form.Select.RichOption>
            <Form.Select.RichOption value="percent">
              Percent off
            </Form.Select.RichOption>
          </Form.Select.Custom>
        )}
      />
    </div>
  )

  const renderAmountField = () => (
    <div className="col-span-12 flex flex-col md:col-span-6 xl:col-span-4">
      <Form.Label htmlFor="amount" required>
        Amount
      </Form.Label>
      {watch("discountUnit") === "percent" ? (
        <>
          <Form.IconTextField
            icon="%"
            position="right"
            hasErrors={!!errors.percentAmount}
            {...register("percentAmount", {
              required: "Amount is required",
              validate: (value) =>
                (value > 0) & (value <= 100) ||
                "Amount must be between 0 and 100",
            })}
            type="number"
            id="amount"
          />
          {errors.percentAmount && (
            <Form.Error>{errors.percentAmount.message}</Form.Error>
          )}
        </>
      ) : (
        <>
          <Form.IconTextField
            icon="$"
            position="left"
            hasErrors={!!errors.amount}
            {...register("amount", { required: "Amount is required" })}
            type="number"
            id="amount"
          />
          {errors.amount && <Form.Error>{errors.amount.message}</Form.Error>}
        </>
      )}
    </div>
  )

  const renderApplicableTypeField = () => (
    <div className="col-span-12 flex flex-col md:col-span-6 xl:col-span-4">
      <Form.Label htmlFor="applicableType">Applies to</Form.Label>
      <Controller
        name="applicableType"
        control={control}
        render={({ field: { onChange } }) => (
          <Form.Select.Custom
            id="applicableType"
            onChange={onChange}
            value={watch("applicableType")}
          >
            <Form.Select.RichOption value="ProductType">
              All fuel items
            </Form.Select.RichOption>
            <Form.Select.RichOption value="Product">
              Selected items
            </Form.Select.RichOption>
          </Form.Select.Custom>
        )}
      />
    </div>
  )

  const renderSelectedProductsField = () => (
    <div className="col-span-12 flex flex-col md:col-span-6 xl:col-span-4">
      <Form.Label required htmlFor="fuelProducts">
        Items
      </Form.Label>
      <Controller
        control={control}
        name="fuelProducts"
        rules={{
          required: "At least one item must be selected",
        }}
        render={({ field: { onChange } }) => (
          <Form.Select.Custom
            onChange={(selected) => {
              setValue(
                "fuelProducts",
                fuelProducts.filter((product) => selected.includes(product.id))
              )
            }}
            value={watch("fuelProducts").map((product) => product.id)}
            hasErrors={!!errors.multi}
            id="fuelProducts"
            multiple
            label={pluralize("item", watch("fuelProducts").length, true)}
          >
            {fuelProducts.map((product, index) => (
              <Form.Select.MultiOption
                value={product.id}
                id={`fuelProducts-${index}`}
                key={index}
              >
                {product.name}
              </Form.Select.MultiOption>
            ))}
          </Form.Select.Custom>
        )}
      />
    </div>
  )

  return (
    <div className="container flex flex-col space-y-4">
      <a href={dockwaPlusDealsPath} className="btn btn-secondary w-min">
        Back to Dockwa Deals
      </a>
      <div className="card flex flex-col space-y-6 p-8">
        <div className="flex flex-col space-y-2">
          <HeadingModalTitle>Fuel Dockwa Deal</HeadingModalTitle>
          <span>
            New fuel deals will be published on the{" "}
            <a href="/deals" className="font-semibold text-blue-600">
              Dockwa+ Deals page
            </a>{" "}
            for boaters to browse and redeem. Running a Dockwa+ Deal will boost
            your marina&apos;s ranking in Dockwa&apos;s boater marketplace (as
            they explore and search), giving your marina more exposure to the
            Dockwa boaters. Dockwa+ Deals are redeemable by Dockwa+ subscribers,
            who are the most active boaters on Dockwa today.
          </span>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-12 gap-4">
            {renderLabelField()}
            {renderDiscountUnitField()}
            {renderAmountField()}
            {renderApplicableTypeField()}
            {watch("applicableType") === "Product" &&
              renderSelectedProductsField()}
          </div>
          <div className="mt-6">
            <Button
              variant="primary"
              type="submit"
              isLoading={isLoading}
              disabled={isLoading || isSuccess}
            >
              {fuelDiscount ? "Update" : "Save and create"} deal
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

FuelDiscountForm.propTypes = {
  fuelDiscount: PropTypes.object,
  fuelProducts: PropTypes.array.isRequired,
  dockwaPlusDealsPath: PropTypes.string.isRequired,
}

export default FuelDiscountForm
