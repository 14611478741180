import PropTypes from "prop-types"
import React, { createContext, useContext, useEffect, useState } from "react"

import detectBarcodeScan from "../utils/detectBarcodeScan"

// 1. Create the context
const BarcodeScannerContext = createContext()

// 2. Export a helper hook so consumers can easily access the context
export const useBarcodeScanner = () => {
  return useContext(BarcodeScannerContext)
}

// 3. Provider component that handles the detectBarcodeScan logic
export const BarcodeScannerProvider = ({ children }) => {
  const [scannedBarcode, setScannedBarcode] = useState("")

  useEffect(() => {
    // The callback passed into detectBarcodeScan will set the code in state
    const handleScan = (code) => {
      setScannedBarcode(code)
    }

    detectBarcodeScan(handleScan)
  }, [])

  return (
    <BarcodeScannerContext.Provider
      value={{ scannedBarcode, setScannedBarcode }}
    >
      {children}
    </BarcodeScannerContext.Provider>
  )
}

BarcodeScannerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
