import React, { createContext, useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { POSContext } from "src/main/PointOfSale/Layout"
import { useBarcodeScanner } from "src/providers/BarcodeScannerProvider"

import { getItemByBarcode } from "src/api/ManageItems"
import { getQuickKeys } from "src/api/PointOfSale/layouts"

import { useModalToggle } from "src/hooks/use_modal_toggle"
import { useToast } from "src/hooks/use_toast"
import useWindowSize from "src/hooks/use_window_size"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import CurrentSalePanel from "./CurrentSalePanel"
import NewLayoutModal from "./NewLayoutModal"
import QuickKeyPanel from "./QuickKeyPanel"
import QuantityInputModal from "./QuickKeyPanel/QuantityInputModal"
import SmallScreenPointOfSale from "./SmallScreen/SmallScreenPointOfSale"
import SwitchLayoutModal from "./SwitchLayoutModal"
import { USE_QUANTITY_MODAL_FOR_TYPES } from "./constants"
import { useSelectedLayout } from "./hooks/useSelectedLayout"

export const QuickKeyPanelContext = createContext()

const PointOfSale = () => {
  const {
    layouts,
    cartController: { addItemToCart },
    marinaAccess,
  } = useContext(POSContext)
  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()
  const [showNewLayoutModal, setShowNewLayoutModal] = useState(false)
  const [showSwitchLayoutModal, setShowSwitchLayoutModal] = useState(false)
  const [selectedQuickKeyGroup, setSelectedQuickKeyGroup] = useState(null)

  const [selectedLayout, setSelectedLayout] = useSelectedLayout()

  const [isQtyModalOpen, showQtyModal, hideQtyModal, qtyModalProps] =
    useModalToggle()

  // 1) Pull the scanned barcode from your BarcodeScannerProvider
  const { scannedBarcode, setScannedBarcode } = useBarcodeScanner()

  // auto-select layout if the selected one is present or there is no selected one
  useEffect(() => {
    if (layouts && layouts.find((l) => l.id === selectedLayout?.id) == null) {
      setSelectedLayout(layouts[0] ?? {})
    }
  }, [layouts, selectedLayout?.id, setSelectedLayout])

  const shouldHandleScannedBarcode = Boolean(scannedBarcode)

  useQuery({
    queryKey: ["products", "barcode", scannedBarcode],
    queryFn: () => getItemByBarcode({ marinaSlug, barcode: scannedBarcode }),
    enabled: shouldHandleScannedBarcode && marinaAccess.barcodeScanner,
    retry: false,
    onSuccess: (item) => {
      handleItemSelect(item)
      setScannedBarcode(null)
    },
    onError: () => {
      showToast(`No item found for barcode: ${scannedBarcode}`, {
        type: "error",
      })
      setScannedBarcode(null)
    },
  })

  const handleItemSelect = (item) => {
    const shouldOpenQtyModal = USE_QUANTITY_MODAL_FOR_TYPES.includes(
      item.product_type
    )
    if (shouldOpenQtyModal) {
      const onComplete = (quantity) => {
        addItemToCart({ item: { ...item, quantity } })
      }
      showQtyModal({ item, onComplete })
    } else {
      addItemToCart({ item })
    }
  }

  const handleQuickKeyClick = (quickKey) => {
    if (quickKey.items.length > 1) {
      setSelectedQuickKeyGroup(quickKey)
    } else {
      const item = quickKey.items[0]
      handleItemSelect(item)
    }
  }

  const { isLargeScreen } = useWindowSize()

  const quickKeysQuery = useQuery({
    queryKey: ["layout", selectedLayout?.id, "quick_keys"],
    queryFn: () => getQuickKeys({ marinaSlug, layoutId: selectedLayout?.id }),
    enabled: selectedLayout?.id != null,
  })

  return (
    <QuickKeyPanelContext.Provider
      value={{
        setShowSwitchLayoutModal,
        setShowNewLayoutModal,
        selectedLayout,
        setSelectedLayout,
        handleItemSelect,
        selectedQuickKeyGroup,
        setSelectedQuickKeyGroup,
        quickKeysQuery,
        handleQuickKeyClick,
      }}
    >
      {/* Adaptive Designs - Small Screens */}
      {!isLargeScreen ? (
        <div className="h-full" data-testid="adaptive-point-of-sale">
          <SmallScreenPointOfSale />
        </div>
      ) : null}

      {/* Responsive Designs - All other Screens */}
      {isLargeScreen ? (
        <div
          data-testid="responsive-point-of-sale"
          className="flex h-full flex-col divide-y md:flex-row md:divide-x md:divide-y-0"
        >
          <div className="flex-2">
            <QuickKeyPanel />
          </div>
          <div className="flex-1">
            <CurrentSalePanel />
          </div>
        </div>
      ) : null}

      {showNewLayoutModal ? (
        <NewLayoutModal onClose={() => setShowNewLayoutModal(false)} />
      ) : null}

      {/* Shared by Adaptive & Responsive */}
      {showSwitchLayoutModal ? (
        <SwitchLayoutModal
          layouts={layouts}
          onClose={() => setShowSwitchLayoutModal(false)}
        />
      ) : null}

      {isQtyModalOpen ? (
        <QuantityInputModal onClose={hideQtyModal} {...qtyModalProps} isOpen />
      ) : null}
    </QuickKeyPanelContext.Provider>
  )
}

export default PointOfSale
